import { render, staticRenderFns } from "./EditProject.vue?vue&type=template&id=97e05506&scoped=true&"
import script from "./EditProject.vue?vue&type=script&lang=js&"
export * from "./EditProject.vue?vue&type=script&lang=js&"
import style0 from "./EditProject.vue?vue&type=style&index=0&id=97e05506&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97e05506",
  null
  
)

export default component.exports